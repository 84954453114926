<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 網域標記列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createDomainMark')">新增網域標記<i class="el-icon-lx-add el-icon--right"></i></el-button>
                <el-button type="primary"
                           @click="domainMarkExport()">excel範例<i class="el-icon-download el-icon--right"></i></el-button>
                <el-button type="success"
                           @click="handleAdds()">批量新增網域標記<i class="el-icon-edit el-icon--right"></i></el-button>
                <el-button type="danger"
                           @click="handleDelete()">刪除勾選網域標記<i class="el-icon-delete el-icon--right"></i></el-button>
                <input ref="excel-upload-input"
                       class="excel-upload-input"
                       type="file"
                       accept=".xlsx, .xls"
                       @change="batchImport">
            </el-col>
        </el-row>
        <div class="container">
            <TableTemplate :colums="colums"
                           v-loading="listLoading"
                           :data="tableData"
                           :paginationShow="true"
                           :pageIndex="pageIndex"
                           :pageCount="pageCount"
                           @change-page="changePage"
                           :selectionShow="true"
                           @selection-change="selectionChange">
                <!-- 是否為汙染 -->
                <template v-slot:is_corrupted="data">
                    <div v-if="data.data.is_corrupted">是</div>
                    <div v-else>否</div>
                </template>
                <!-- 是否為昂貴域名 -->
                <template v-slot:is_expensive="data">
                    <div v-if="data.data.is_expensive">是</div>
                    <div v-else>否</div>
                </template>
                <!-- operate -->
                <template v-slot:operate="data">
                    <el-button icon="el-icon-edit"
                               size="mini"
                               @click="toPage('editDomainMark',data.data,'edit')">编辑</el-button>
                </template>
            </TableTemplate>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../../../components/TableTemplate.vue'
import { domainMarkList, domainMarkDelete, domainMarkListExport, domainMarkbatchImport } from '../../../api/index'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            colums: [
                {
                    name: '域名',
                    prop: 'domain',
                },
                {
                    name: '是否為汙染',
                    type: 'slot',
                    attribute: { slotName: 'is_corrupted' },
                },
                {
                    name: '是否為昂貴域名',
                    type: 'slot',
                    attribute: { slotName: 'is_expensive' },
                },
                {
                    name: '備註',
                    prop: 'note',
                },
                {
                    name: '操作',
                    type: 'slot',
                    attribute: { slotName: 'operate' },
                },
            ],
            tableData: [],
            selectionId: [], //選取到的
            pageIndex: 1,
            pageCount: 0,
            listLoading: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.listLoading = true
            let data = {
                page: this.pageIndex,
            }
            domainMarkList(data).then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_list
                    this.pageCount = res.data.total_page
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
                this.listLoading = false
            })
        },
        toPage(page, info, edit) {
            if (edit == 'edit') {
                this.$router.push({
                    path: page,
                    query: { id: info.id },
                })
            } else {
                this.$router.push({ path: page })
            }
        },
        handleAdds() {
            this.$refs['excel-upload-input'].click()
        },
        //範例匯出
        domainMarkExport() {
            domainMarkListExport().then((res) => {
                this.excelTake(res, '批量匯入範例')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        //批量匯入
        batchImport(e) {
            const files = e.target.files
            const rawFile = files[0]
            let formData = new FormData()
            formData.append('excel', rawFile)
            domainMarkbatchImport(formData)
                .then((res) => {
                    if (res.code == 200) {
                        this.getData()
                        this.$message.success(res.message)
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.$refs['excel-upload-input'].value = ''
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        changePage(val) {
            this.pageIndex = val
            this.getData()
        },
        handleDelete() {
            this.$confirm('確定要刪除嗎？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    domainMarkDelete({ id: this.selectionId })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success(res.message)
                                this.getData()
                            }
                        })
                        .catch(() => {
                            this.$message.error('失敗')
                        })
                })
                .catch(() => {})
        },
        selectionChange(val) {
            this.selectionId = []
            val.forEach((element) => {
                this.selectionId.push(element.id)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
.excel-upload-input {
    display: none;
}
</style>